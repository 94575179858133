import { ref } from 'vue'
import { defineStore } from 'pinia'

interface Employee {
  _id?: string;
  first_name: string;
  last_name?: string;
  middle_name?: string;
  email?: string;
  accessLevel?: string;
}

export const useStore = defineStore('index', () => {

  const companyNameUse = ref('')
  const titleHeaderProfile = ref('')
  const objectEmployee = ref<Employee>({
    _id: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    accessLevel: '',
  })

  // Действия
  const setObjectEmployee = (newObj: Employee) => {
    objectEmployee.value = newObj;
  };

  const setTitleHeaderProfile = (newTitle: string) => {
    titleHeaderProfile.value = newTitle;
  };

  return {
    companyNameUse,
    setObjectEmployee,
    objectEmployee,
    setTitleHeaderProfile,
    titleHeaderProfile,
  }
})
