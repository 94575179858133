// services/companyService.ts
import axios from 'axios';
import { useStore } from '@/stores/index';
const API_URL = import.meta.env.VITE_API_URL;

export async function checkCompanyExistence(companyPath: string): Promise<boolean> {

  try {

    const response = await axios.get(`${API_URL}/accountingCompany/checkPath/${companyPath}`);
    const { name } = response.data
    useStore().companyNameUse = name

    return true; // Предполагается, что статус 200 означает, что компания существует
  } catch (error) {

    console.error('Ошибка при проверке существования компании:', error);
    return false; // Если произошла ошибка, считаем, что компания не существует
  }

}


