import './assets/main.css'
import 'primeicons/primeicons.css'; // Иконки

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import Aura from "@primevue/themes/aura";
import {definePreset} from "@primevue/themes";

import App from './App.vue'
import router from './router'

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import DatePicker from "primevue/datepicker";
import InputMask from 'primevue/inputmask';
import FloatLabel from 'primevue/floatlabel';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Textarea from 'primevue/textarea';
import AutoComplete from 'primevue/autocomplete';
import Message from 'primevue/message';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import Menu from 'primevue/menu';
import PanelMenu from 'primevue/panelmenu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Rating from 'primevue/rating';
import Tooltip from 'primevue/tooltip';
import Skeleton from 'primevue/skeleton';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import Badge from 'primevue/badge';
import OverlayBadge from 'primevue/overlaybadge';
import Divider from 'primevue/divider';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import ToggleSwitch from 'primevue/toggleswitch';
import {useThemeStore} from "@/stores/themeStore";




const app = createApp(App)

app.use(createPinia())
const theme = useThemeStore()
app.use(router)
app.use(PrimeVue, {
    theme: {
        preset: definePreset(Aura, theme.Noir),
        options: {
            prefix: 'p',
            darkModeSelector: '.dark',
            cssLayer: {
                name: 'primevue',
                order: 'tailwind-base, primevue, tailwind-utilities'
            }
        }
    }
});
app.use(ToastService);
app.use(ConfirmationService);

app.component('DatePicker', DatePicker);
app.component('InputMask', InputMask);
app.component('FloatLabel', FloatLabel);
app.component('InputText', InputText);
app.component('Select', Select);
app.component('Textarea', Textarea);
app.component('AutoComplete', AutoComplete);
app.component('Message', Message);
app.component('Card', Card);
app.component('Button', Button);
app.component('Toast', Toast);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Menu', Menu);
app.component('PanelMenu', PanelMenu);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Rating', Rating);
app.component('Skeleton', Skeleton);
app.component('Tabs', Tabs);
app.component('TabList', TabList);
app.component('Tab', Tab);
app.component('TabPanels', TabPanels);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag)
app.component('Chip', Chip);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Badge', Badge);
app.component('OverlayBadge', OverlayBadge);
app.component('Divider', Divider);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('ToggleSwitch', ToggleSwitch);

app.directive('tooltip', Tooltip);

app.mount('#app')

export default router;

