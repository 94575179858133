import {createRouter, createWebHistory, type RouteRecordRaw} from 'vue-router';
import authGuard from './authGuard';
import { useAuthStore } from '@/stores/authStore';
import { checkCompanyExistence } from '@/services/companyService'
import employeeRoutes from '@/router/employeeRoutes';
import globalAdminRoutes from "@/router/globalAdminRoutes";


const routes = [
    ...employeeRoutes,
    ...globalAdminRoutes,
    {
        path: '/',
        name: 'Home',
        component: () => import('@/components/Home.vue'),
    },
    {
        path: '/login-admin',
        name: 'LoginAdmin',
        component: () => import('@/components/GlobalAdmin/GlobalAdminLogin.vue'),
    },
    {
        path: '/:companyPath',
        name: 'CompanyBase',
        component: () => import('@/components/CompanyBaseComponent.vue'),
        props: true,
        beforeEnter: async (to: any, from: any, next: any) => {

            const status = await checkCompanyExistence(to.params.companyPath);

            if (!status) {
                console.log('Компания не существует, перенаправляем на NotFound');
                next({ name: 'NotFound' });
            } else if (localStorage.getItem('isAuthenticated') === 'true') {
                const authStore = useAuthStore();
                const userRole = authStore.userRole;
                console.log('Пользователь аутентифицирован, роль:', userRole);

                if (userRole === 'employee') {
                    next({ name: 'ProfileEmployee', params: { companyPath: to.params.companyPath } });
                } else if (userRole === 'client') {
                    next({ name: 'ProfileClient', params: { companyPath: to.params.companyPath } });
                } else {
                    next({ name: 'Home' });
                }
            } else {
                console.log('Пользователь не аутентифицирован, перенаправляем на LoginEmployee');
                next({name: 'LoginEmployee', params: { companyPath: to.params.companyPath}});
            }
        },
    },
    {
        path: '/:companyPath/cl',
        name: 'ClientBase',
        props: true,
        component: () => import('@/components/ClientBaseComponent.vue'),
        beforeEnter: async (to: any, from: any, next: any) => {

            const companyExists = await checkCompanyExistence(to.params.companyPath);
            if (!companyExists) {
                next({ name: 'NotFound' });
            } else if (localStorage.getItem('isAuthenticated') === 'true') {
                const authStore = useAuthStore();
                const userRole = authStore.userRole;
                if (userRole === 'client') {
                    next({ name: 'ProfileClient', params: { companyPath: to.params.companyPath } });
                } else if (userRole === 'employee') {
                    next({ name: 'ProfileEmployee', params: { companyPath: to.params.companyPath } });
                } else {
                    next({ name: 'Home' });
                }
            } else {
                next({ name: 'LoginClient', params: { companyPath: to.params.companyPath } });
            }
        },
    },
    {
        path: '/:companyPath/cl/login-client',
        name: 'ClientBaseLogin',
        props: true,
        component: () => import('@/components/ClientBaseComponent.vue'),
        beforeEnter: async (to: any, from: any, next: any) => {
           

            const companyExists = await checkCompanyExistence(to.params.companyPath);
            if (!companyExists) {
                next({ name: 'NotFound' });
            } else if (localStorage.getItem('isAuthenticated') === 'true') {
                const authStore = useAuthStore();
                const userRole = authStore.userRole;
                if (userRole === 'client') {
                    next({ name: 'ProfileClient', params: { companyPath: to.params.companyPath } });
                } else if (userRole === 'employee') {
                    next({ name: 'ProfileEmployee', params: { companyPath: to.params.companyPath } });
                } else {
                    next({ name: 'Home' });
                }
            } else {
                next({ name: 'LoginClient', params: { companyPath: to.params.companyPath } });
            }
        },
    },
    {
        path: '/:companyPath/login-employee',
        name: 'LoginEmployee',
        component: () => import('@/components/Emploees/EmploeeLogin.vue'),
        props: true,
        beforeEnter: async (to: any, from: any, next: any) => {

            const companyExists = await checkCompanyExistence(to.params.companyPath);
            // console.log('companyExists1', companyExists);
            if (!companyExists) {
                next({ name: 'NotFound' });
            } else {
                next();
            }
        },
    },
    {
        path: '/:companyPath/cl/login-client',
        name: 'LoginClient',
        component: () => import('@/components/Clients/ClientLogin.vue'),
        props: true,
        beforeEnter: async (to: any, from: any, next: any) => {
            const companyExists = await checkCompanyExistence(to.params.companyPath);
            if (!companyExists) {
                next({ name: 'NotFound' });
            } else {
                next();
            }
        },
    },
    {
        path: '/:companyPath/profile-client',
        name: 'ProfileClient',
        component: () => import('@/views/ProfileLayoutClient.vue'),
        props: true,

    },
    {
        path: '/:pathMatch(.*)*', // Любой нераспознанный маршрут
        name: 'NotFound',
        component: () => import('@/components/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

// Добавляем охранник навигации
router.beforeEach(authGuard);

export default router;
