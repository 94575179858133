import {defineStore} from 'pinia';
import {getProtectedData} from '@/services/protectedService';
import {stopTokenRefresh, startTokenRefresh} from '@/utils/tokenManager';
import axios from "axios";
import {ref} from 'vue';
import {useStore} from "@/stores/index";
import {useRouter, useRoute} from 'vue-router';


export const useAuthStore = defineStore('auth', () => {
    const store = useStore()
    const router = useRouter();
    const route = useRoute()
    // Состояние
    const isAuthenticated = ref(localStorage.getItem('isAuthenticated') === 'true');
    const userRole = ref('');
    const companyPath = ref('');

    // Действия
    const checkAuthentication = async (): Promise<void> => {
        console.log(788, route.name)

        // if (route.name !== '')
        try {
            const request = await getProtectedData(); // Попытка получить защищенные данные

            if (companyPath.value && !store.objectEmployee.first_name) {
                const req = await getOneEmployee(companyPath.value, request._id)
                await store.setObjectEmployee(req)
            }

            isAuthenticated.value = true; // Если успешно, пользователь аутентифицирован
            userRole.value = request.role
            localStorage.setItem('isAuthenticated', 'true');
            startTokenRefresh()
        } catch (error) {
            logout() // Если ошибка, пользователь не аутентифицирован
            console.error('Authentication check failed:', error);
        }


    }

    const setAuthenticated = (status: boolean) => {
        isAuthenticated.value = status;
        localStorage.setItem('isAuthenticated', `${status}`);
    }
    // setUserRole(role: string) {
    //     this.userRole = role;
    // },


    const getOneEmployee = async (companyPath: string, id: string) => {

        try {
            if (!companyPath) return null

            const req = await axios.get(
                `${import.meta.env.VITE_API_URL}/employees/${companyPath}/getOneEmployee/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true // Обязательно!
                })
            delete req.data.password
            return req.data;
        } catch (error) {
            console.error('getOneEmployee failed:', error);
        }
    }

    const logout = async (): Promise<void> => {

        // Перенаправляем на страницу логина, соответствующую роли
        if (userRole.value) {
            stopTokenRefresh();
            isAuthenticated.value = false;
            localStorage.removeItem('isAuthenticated');

            if (userRole.value === 'globalAdmin') {
                await router.push({name: 'LoginAdmin'});
            } else if (userRole.value === 'employee') {
                await router.push({name: 'LoginEmployee', params: {companyPath: companyPath.value}});
            } else if (userRole.value === 'client') {
                await router.push({name: 'LoginClient', params: {companyPath: companyPath.value}});
            } else {
                await router.push({name: 'Home'}); // Перенаправляем на домашнюю страницу, если роль не указана
            }
            userRole.value = '';


        }
    }

    const setCompanyPath = (path: string) => {
        companyPath.value = path;
    };

    return {
        isAuthenticated,
        userRole,
        companyPath,
        checkAuthentication,
        setAuthenticated,
        logout,
        setCompanyPath,
    };

});


