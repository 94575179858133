// src/utils/tokenManager.ts
import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const API_URL = import.meta.env.VITE_API_URL;

export const saveTokenExpiration = (expiresIn: number): void => {
 
    const expirationTime = Date.now() + expiresIn * 1000; // expiresIn в секундах
    localStorage.setItem('tokenExpiration', expirationTime.toString());
};

export const fetchWithTokenRefresh = async <T>(fetchFunction: () => Promise<T>): Promise<T> => {
    const authStore = useAuthStore();


    try {
        return await fetchFunction();
    } catch (error: any) {
        if (error.response && error.response.status === 403) {
            try {
                // Попытка обновить токен
                const response = await axios.post(`${API_URL}/auth/refresh-token`, {}, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });

                // Предполагается, что сервер возвращает новый токен и expiresIn
                const newExpiresIn: number = response.data.expiresIn; // Получаем expiresIn из ответа
                saveTokenExpiration(newExpiresIn); // Сохраняем новое время истечения токена

                // Повторите запрос с новым access токеном
                return await fetchFunction();
            } catch (refreshError) {
                // Если обновление токена не удалось, выполнить выход из системы
                console.error('Ошибка обновления токена:', refreshError);


                authStore.logout(); // Выход пользователя из системы
                // router.push({ name: 'Login' }); // Перенаправление на страницу логина
                throw refreshError;
            }
        } else {
            throw error; // Если ошибка не связана с токеном, пробрасываем ее дальше
        }
    }
};

// Настройка автоматического обновления токенов
let isTokenRefreshScheduled = false; // Флаг для проверки
export const startTokenRefresh = (): void => {

    if (isTokenRefreshScheduled) return; // Если уже запланировано, не выполняем снова

    const expirationTime = localStorage.getItem('tokenExpiration'); // Получаем время истечения токена
    if (expirationTime) {
        const expirationTimeNum = Number(expirationTime); // Преобразуем в число
        const currentTime = Date.now(); // Получаем текущее время
        const remainingTime = expirationTimeNum - currentTime; // Осталось времени до истечения токена

        if (remainingTime > 0) {
            const refreshInterval = remainingTime - 15000; // Устанавливаем интервал за 10 секунд до истечения

            // Проверяем, что refreshInterval положительный
            if (refreshInterval > 0) {
                isTokenRefreshScheduled = true; // Устанавливаем флаг, что обновление запланировано
                console.log('токен обновится через 1:', refreshInterval);

                setTimeout(async () => {
                    try {
                        console.log('Запрос /auth/refresh-token');
                        const response = await axios.post(`${API_URL}/auth/refresh-token`, {}, {
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            withCredentials: true
                        });
                        console.log('Ответ от сервера:', response.data);
                        const newExpiresIn: number = response.data.expiresIn; // Получаем expiresIn из ответа

                        saveTokenExpiration(newExpiresIn); // Сохраняем новое время истечения токена

                        isTokenRefreshScheduled = false; // Сбрасываем флаг после обновления
                        startTokenRefresh(); // Перезапускаем процесс обновления токенов с новым временем истечения
                        console.log('Запрос успешно /auth/refresh-token');
                    } catch (error) {
                        console.error('Ошибка при обновлении токена:', error);
                    }
                }, refreshInterval);
            } else {
                console.log('refreshInterval не положительный, обновление не запланировано');
            }
        } else { console.log('Токен уже истек, обновление не требуется');
        }
    } else {
        console.log('Время истечения токена не найдено');
    }
};

// Остановка обновления токенов
export const stopTokenRefresh = (): void => {
    // Если вы используете идентификаторы таймеров, вы можете очистить их здесь
    localStorage.removeItem('tokenExpiration');
};