// file authGuard.ts
// import type { NavigationGuard } from 'vue-router';
// import { useAuthStore } from '@/stores/authStore';
//
// const protectedRoutes = ['ProfileAdmin', 'ProfileEmployee', 'ProfileClient'];
//
// const authGuard: NavigationGuard = async (to, from, next) => {
//   const authStore = useAuthStore();
//
//   // Получаем информацию о пользователе из хранилища
//   const isAuthenticated = authStore.isAuthenticated;
//   const userRole = authStore.userRole;
//
//   // Проверяем, если маршрут защищен
//   const isProtectedRoute = protectedRoutes.includes(to.name as string);
//
//   if (isProtectedRoute) {
//     if (isAuthenticated) {
//       // Проверяем соответствие роли пользователя и запрашиваемого профиля
//       if (
//           (userRole === 'globalAdmin' && to.name === 'ProfileAdmin') ||
//           (userRole === 'employee' && to.name === 'ProfileEmployee') ||
//           (userRole === 'client' && to.name === 'ProfileClient')
//       ) {
//         next(); // Доступ разрешен
//       } else {
//         // Перенаправляем в правильный профиль, если роль не совпадает
//         switch (userRole) {
//           case 'globalAdmin':
//             next({ name: 'ProfileAdmin' });
//             break;
//           case 'employee':
//             next({ name: 'ProfileEmployee', params: { companyPath: to.params.companyPath } });
//             break;
//           case 'client':
//             next({ name: 'ProfileClient', params: { companyPath: to.params.companyPath } });
//             break;
//           default:
//             next({ name: 'Home' }); // Или на домашнюю страницу, если роль не соответствует
//         }
//       }
//     } else {
//       // Если не аутентифицирован, перенаправляем на страницу входа
//       if (to.name === 'ProfileAdmin') {
//         next({ name: 'LoginAdmin' });
//       } else if (to.name === 'ProfileEmployee' && to.params.companyPath) {
//         next({ name: 'LoginEmployee', params: { companyPath: to.params.companyPath } });
//       } else if (to.name === 'ProfileClient' && to.params.companyPath) {
//         next({ name: 'LoginClient', params: { companyPath: to.params.companyPath } });
//       } else {
//         next(); // Разрешаем доступ к незащищенным маршрутам
//       }
//     }
//   } else {
//     next(); // Разрешаем доступ к незащищенным маршрутам
//   }
// };
//
// export default authGuard;

/*
import type {NavigationGuard} from 'vue-router';
import {useAuthStore} from '@/stores/authStore';

const protectedRoutes = ['ProfileAdmin', 'ProfileEmployee', 'ProfileClient'];

const authGuard: NavigationGuard = async (to, from, next) => {
    const authStore = useAuthStore();

    if (localStorage.getItem('isAuthenticated') === 'true') {
        await authStore.checkAuthentication(); // Проверяем аутентификацию
    }

    const isAuthenticated = authStore.isAuthenticated;
    const userRole = authStore.userRole;

    // Проверяем, является ли маршрут защищенным
    const isProtectedRoute = protectedRoutes.includes(to.name as string);

    if (isProtectedRoute) {
        if (isAuthenticated) {
            // Проверяем, соответствует ли роль пользователя запрашиваемому профилю
            if (
                (userRole === 'globalAdmin' && to.name === 'ProfileAdmin') ||
                (userRole === 'employee' && to.name === 'ProfileEmployee') ||
                (userRole === 'client' && to.name === 'ProfileClient')
            ) {
                next(); // Разрешить доступ к соответствующему профилю
            } else {
                // Перенаправляем на соответствующий профиль пользователя, если он пытается зайти не в свой профиль
                switch (userRole) {
                    case 'globalAdmin':
                        next({name: 'ProfileAdmin'});
                        break;
                    case 'employee':
                        next({name: 'ProfileEmployee', params: {companyPath: to.params.companyPath}});
                        break;
                    case 'client':
                        next({name: 'ProfileClient', params: {companyPath: to.params.companyPath}});
                        break;
                    default:
                        next({name: 'Home'}); // Или на домашнюю страницу, если роль не соответствует
                }

            }
        } else {
            // Если пользователь не аутентифицирован, перенаправляем на страницу входа
            if (to.name === 'ProfileAdmin') {
                next({name: 'LoginAdmin'});
            } else if (to.name === 'ProfileEmployee' && to.params.companyPath) {
                next({name: 'LoginEmployee', params: {companyPath: to.params.companyPath}});
            } else if (to.name === 'ProfileClient' && to.params.companyPath) {
                next({name: 'LoginClient', params: {companyPath: to.params.companyPath}});
            } else {
                next(); // Разрешить доступ к другим маршрутам
            }
        }
    } else {
        next(); // Разрешить доступ к другим маршрутам
    }

};

export default authGuard;

*/

import type { NavigationGuard } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';

const protectedRoutes = ['ProfileAdmin', 'ProfileEmployee', 'ProfileClient'];
const loginRoutes = ['LoginAdmin', 'LoginEmployee', 'LoginClient'];

const authGuard: NavigationGuard = async (to, from, next) => {
    const authStore = useAuthStore();

    if (localStorage.getItem('isAuthenticated') === 'true') {
        await authStore.checkAuthentication(); // Проверяем аутентификацию
    }

    const isAuthenticated = authStore.isAuthenticated;
    const userRole = authStore.userRole;

    // Если пользователь авторизован и пытается перейти на страницу входа, перенаправляем его на профиль
    if (isAuthenticated && loginRoutes.includes(to.name as string) && userRole) {
        switch (userRole) {
            case 'globalAdmin':
                next({ name: 'ProfileAdmin' });
                break;
            case 'employee':
                next({ name: 'ProfileEmployee', params: { companyPath: to.params.companyPath } });
                break;
            case 'client':
                next({ name: 'ProfileClient', params: { companyPath: to.params.companyPath } });
                break;
            default:
                console.log(222, 'ddd')
                next({ name: 'Home' });
        }
        return; // Завершаем обработку guard после перенаправления
    }

    // Проверяем, является ли маршрут защищенным
    const isProtectedRoute = protectedRoutes.includes(to.name as string);

    if (isProtectedRoute) {
        if (isAuthenticated) {
            // Проверяем, соответствует ли роль пользователя запрашиваемому профилю
            if (
                (userRole === 'globalAdmin' && to.name === 'ProfileAdmin') ||
                (userRole === 'employee' && to.name === 'ProfileEmployee') ||
                (userRole === 'client' && to.name === 'ProfileClient')
            ) {
                next(); // Разрешить доступ к соответствующему профилю
            } else {
                // Перенаправляем на соответствующий профиль пользователя, если он пытается зайти не в свой профиль
                switch (userRole) {
                    case 'globalAdmin':
                        next({ name: 'ProfileAdmin' });
                        break;
                    case 'employee':
                        next({ name: 'ProfileEmployee', params: { companyPath: to.params.companyPath } });
                        break;
                    case 'client':
                        next({ name: 'ProfileClient', params: { companyPath: to.params.companyPath } });
                        break;
                    default:
                        next({ name: 'Home' });
                }
            }
        } else {
            // Если пользователь не аутентифицирован, перенаправляем на страницу входа
            if (to.name === 'ProfileAdmin') {
                next({ name: 'LoginAdmin' });
            } else if (to.name === 'ProfileEmployee' && to.params.companyPath) {
                next({ name: 'LoginEmployee', params: { companyPath: to.params.companyPath } });
            } else if (to.name === 'ProfileClient' && to.params.companyPath) {
                next({ name: 'LoginClient', params: { companyPath: to.params.companyPath } });
            } else {
                next(); // Разрешить доступ к другим маршрутам
            }
        }
    } else {
        next(); // Разрешить доступ к другим маршрутам
    }
};

export default authGuard;


