// src/services/protectedService.ts
import axios from 'axios'; // Импортируем AxiosError из axios
import type { CustomAxiosError } from '@/types/types'; // Импортируем ваш интерфейс AxiosError
import { fetchWithTokenRefresh } from '@/utils/tokenManager.js';

const API_URL = import.meta.env.VITE_API_URL;

export const getProtectedData = async () => {
    try {
        const response = await fetchWithTokenRefresh(() =>axios.get(`${API_URL}/protected`, {
            withCredentials: true, // Важно для отправки HttpOnly куки
        }));
        console.log('Проверка на подлинность пройдена.');

        return response.data; // Возвращаем данные ответа
    } catch (error: unknown) {
        if (isAxiosError(error) && error.response?.status === 403) {
            console.error('Нет доступа к ресурсу:', error.response.data);
            return ''
        }

    }
};



// Функция для проверки, является ли ошибка экземпляром AxiosError
function isAxiosError(error: any): error is CustomAxiosError {
    return error && typeof error === 'object' && 'response' in error;
}
