// frontend/project-saas/src/router/employeeRoutes.ts
import {useAuthStore} from '@/stores/authStore';


const globalAdminRoutes = [{
    path: '/ProfileAdmin',
    name: 'ProfileAdmin',
    component: () => import('@/views/ProfileLayoutAdmin.vue'),
    props: true,
    beforeEnter: async (to: any, from: any, next: any) => {
        const authStore = useAuthStore();
        const isAuthenticatedLocalStorage = localStorage.getItem('isAuthenticated') === 'true';

        if (isAuthenticatedLocalStorage && authStore.isAuthenticated && authStore.userRole === 'globalAdmin') {
            next(); // Разрешаем доступ, если пользователь аутентифицирован
        } else {
            next({name: 'LoginAdmin'}); // Перенаправляем на страницу входа
        }
    },
    children: [
        {
            path: '',
            name: 'HomeGlobalAdmin',
            component: () => import('@/pages/GlobalAdmins/HomeGlobalAdmin.vue'),
        }, {
            path: '/ListClientsSaas',
            name: 'ListClientsSaas',
            component: () => import('@/pages/GlobalAdmins/ListClientsSaas.vue'),
        }, {
            path: '/InfoClientSaas/:id',
            name: 'InfoClientSaas',
            component: () => import('@/pages/GlobalAdmins/InfoClientSaas.vue'),
        }, {
            path: '/AddClientSaas',
            name: 'AddClientSaas',
            component: () => import('@/pages/GlobalAdmins/AddClientSaas.vue'),
        }, {
            path: '/EditClientSaas/:id',
            name: 'EditClientSaas',
            component: () => import('@/pages/GlobalAdmins/EditClientSaas.vue'),
        },

    ]

},
]

export default globalAdminRoutes;