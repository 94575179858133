// frontend/project-saas/src/router/employeeRoutes.ts
import {useAuthStore} from '@/stores/authStore';


const employeeRoutes = [
    {
        path: '/:companyPath/profile-employee',
        name: 'ProfileEmployee',
        component: () => import('@/views/ProfileLayoutEmployee.vue'),
        props: true,
        beforeEnter: async (to: any, from: any, next: any) => {
            const authStore = useAuthStore();
            const isAuthenticatedLocalStorage = localStorage.getItem('isAuthenticated') === 'true';
            const isAuthenticatedAuthStore = authStore.isAuthenticated;
            const userRole = authStore.userRole;

            if (isAuthenticatedLocalStorage && isAuthenticatedAuthStore && userRole === 'employee') {

                next(); // Разрешаем доступ, если пользователь аутентифицирован
            } else {
                next({name: 'LoginEmployee', params: {companyPath: to.params.companyPath}}); // Перенаправляем на страницу входа
            }
        },
        children: [
            {
                path: '',
                name: 'HomeEmployee',
                component: () => import('@/pages/Emploees/HomeEmployee.vue'),
            },
            {
                path: 'addClient',
                name: 'addClient',
                component: () => import('@/pages/Emploees/AddClient.vue'),
            },
            {
                path: 'editClient/:id',
                name: 'editClient',
                component: () => import('@/pages/Emploees/EditClient.vue'),
            },
            {
                path: 'listClient',
                name: 'listClient',
                component: () => import('@/pages/Emploees/ListClients.vue'),
            },
            {
                path: 'infoClient/:id',
                name: 'infoClient',
                component: () => import('@/pages/Emploees/InfoClient.vue'),
            },
            {
                path: 'CompanyClient/:id',
                name: 'CompanyClient',
                component: () => import('@/pages/Emploees/CompanyClient.vue'),
            },
            {
                path: 'AddCompany/:id',
                name: 'AddCompany',
                component: () => import('@/pages/Emploees/AddCompany.vue'),
            },
            {
                path: 'EditCompany/:id',
                name: 'EditCompany',
                component: () => import('@/pages/Emploees/EditCompany.vue'),
            },
            {
                path: 'setting-employee',
                name: 'SettingsEmployee',
                component: () => import('@/pages/Emploees/Settings.vue'),
            },

        ]

    },
]

export default employeeRoutes;