import { defineStore } from 'pinia';
import {computed, ref} from 'vue';
import {definePreset, usePreset} from '@primevue/themes';
import Aura from "@primevue/themes/aura";

export const useThemeStore = defineStore('theme', () => {
    // Состояние для темы
    const isDarkMode = ref(localStorage.getItem('theme') === 'dark'); // Инициализация из localStorage

    // Цветовые настройки
    const semanticPrimary = ref(localStorage.getItem('semanticPrimary') || 'emerald');
    const darkSurface = ref(localStorage.getItem('darkSurface') || 'zinc');
    const lightSurface = ref(localStorage.getItem('lightSurface') || 'zinc');


    const Noir = computed(() => ( {
        semantic: {
            primary: {
                50: `{${semanticPrimary.value}.50}`,
                100: `{${semanticPrimary.value}.100}`,
                200: `{${semanticPrimary.value}.200}`,
                300: `{${semanticPrimary.value}.300}`,
                400: `{${semanticPrimary.value}.400}`,
                500: `{${semanticPrimary.value}.500}`,
                600: `{${semanticPrimary.value}.600}`,
                700: `{${semanticPrimary.value}.700}`,
                800: `{${semanticPrimary.value}.800}`,
                900: `{${semanticPrimary.value}.900}`,
                950: `{${semanticPrimary.value}.950}`
            },

            colorScheme: {
                light: {
                    primary: {
                        color: `{${semanticPrimary.value}.500}`,
                        inverseColor: '#ffffff',
                        hoverColor: `{${semanticPrimary.value}.600}`,
                        activeColor: `{${semanticPrimary.value}.800}`
                    },
                    highlight: {
                        background: `{${semanticPrimary.value}.500}`,
                        focusBackground: `{${semanticPrimary.value}.700}`,
                        color: '#ffffff',
                        focusColor: '#ffffff'
                    },
                    surface: {
                        0: '#ffffff',
                        50: `{${lightSurface.value}.50}`,
                        100: `{${lightSurface.value}.100}`,
                        200: `{${lightSurface.value}.200}`,
                        300: `{${lightSurface.value}.300}`,
                        400: `{${lightSurface.value}.400}`,
                        500: `{${lightSurface.value}.500}`,
                        600: `{${lightSurface.value}.600}`,
                        // 700: `{${lightSurface.value}.700}`,
                        800: `{${lightSurface.value}.800}`,
                        900: `{${lightSurface.value}.900}`,
                        950: `{${lightSurface.value}.950}`
                    },
                },
                dark: {
                    primary: {
                        color: '{zinc.200}',
                        inverseColor: '{zinc.950}',
                        hoverColor: '{zinc.100}',
                        activeColor: '{zinc.200}'
                    },
                    highlight: {
                        background: 'rgba(250, 250, 250, .16)',
                        focusBackground: 'rgba(250, 250, 250, .24)',
                        color: 'rgba(255,255,255,.87)',
                        focusColor: 'rgba(255,255,255,.87)'
                    },
                    surface: {
                        0: '#ffffff',
                        50: `{${darkSurface.value}.50}`,
                        100: `{${darkSurface.value}.100}`,
                        200: `{${darkSurface.value}.200}`,
                        300: `{${darkSurface.value}.300}`,
                        400: `{${darkSurface.value}.400}`,
                        500: `{${darkSurface.value}.500}`,
                        600: `{${darkSurface.value}.600}`,
                        700: `{${darkSurface.value}.700}`,
                        800: `{${darkSurface.value}.800}`,
                        900: `{${darkSurface.value}.900}`,
                        950: `{${darkSurface.value}.950}`
                    },


                },


            },

        },

    }));


    // Функция для переключения темы
    const toggleDarkMode = () => {
        isDarkMode.value = !isDarkMode.value;
        localStorage.setItem('theme', isDarkMode.value ? 'dark' : 'light');
        if (isDarkMode.value) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    // Функция для изменения цвета
    const setThemeColor = (type: string, color: string) => {

        switch (type) {
            case 'semanticPrimary':
                semanticPrimary.value = color;
                localStorage.setItem('semanticPrimary', color);

                break;
            case 'darkSurface':
                darkSurface.value = color;
                localStorage.setItem('darkSurface', color);
                break;
            case 'lightSurface':
                lightSurface.value = color;
                localStorage.setItem('lightSurface', color);
                break;
            default:
                console.warn(`Unknown color type: ${type}`);
        }

        usePreset(definePreset(Aura, Noir.value))
    };


    return {
        isDarkMode,
        semanticPrimary,
        darkSurface,
        lightSurface,
        toggleDarkMode,
        setThemeColor,
        Noir
    };
});



